<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">我的订餐</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <van-tabs v-model="active" swipeable sticky>
        <van-tab title="预定">
          <!-- <div class="select">
            <div class="select-item" @click="isShowCanteenListDialog = true">
              <div class="name">{{ currentCanteenName }}</div>
              <img src="../../assets/images/bottomImg.png" alt="" />
            </div>
          </div> -->
          <div class="reverse">
            <van-list
              v-if="mealList && mealList.length > 0"
              v-model="mealLoading"
              :finished="mealFinished"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onMealLoad"
            >
              <div
                v-for="(item, index) in mealList"
                :key="index"
                class="content_detail"
                style="
                  background: #fff;
                  margin-bottom: 15px;
                  border-radius: 10px;
                "
              >
                <div class="detail_top">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      color: #333;
                    "
                  >
                    <img
                      mode="scaleToFill"
                      style="width: 21px; height: 21px; margin-right: 10px"
                      :src="
                        item.type === 'breakfast'
                          ? breakfast
                          : item.type === 'lunch'
                          ? lunch
                          : item.type === 'dinner'
                          ? dinner
                          : ''
                      "
                    />
                    <span style="margin-right: 10px; font-weight: bold">
                      {{ date(item.date) }}
                    </span>
                    <span style="font-weight: bold">
                      ({{ week(item.date) }})
                    </span>
                  </div>
                  <div
                    style="color: #333333; font-size: 16px; font-weight: 700"
                  >
                    {{ item.canteenName || '食堂' }}
                  </div>
                </div>
                <div class="detail_content">
                  <div
                    style="
                      height: 44px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin: 0 15px;
                      font-size: 16px;
                      color: #333;
                    "
                  >
                    <div>就餐时间</div>
                    <div
                      style="
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{
                        subTime(item.mealStart) + '~' + subTime(item.mealEnd)
                      }}
                    </div>
                  </div>
                  <div
                    style="
                      height: 44px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin: 0 15px;
                      font-size: 16px;
                      color: #333;
                    "
                  >
                    <div>订单截止时间</div>
                    <div
                      style="
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ item.endTime }}
                    </div>
                  </div>
                  <div
                    class="list"
                    style="
                      height: 55px;
                      display: flex;
                      justify-content: space-between;
                      align-items: start;
                      margin: 12px 15px;
                      font-size: 16px;
                      color: #333;
                    "
                  >
                    <div>就餐类型</div>
                    <div
                      style="
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <van-radio-group v-model="item.flag">
                        <van-radio
                          :name="false"
                          checked-color="#00b893"
                          style="margin-bottom: 10px"
                        >
                          堂食
                        </van-radio>
                        <van-radio :name="true" checked-color="#00b893">
                          外送
                        </van-radio>
                      </van-radio-group>
                    </div>
                  </div>
                  <div
                    v-if="item.flag"
                    class="address"
                    style="
                      height: 60px;
                      display: flex;
                      align-items: start;
                      margin: 0 15px;
                      font-size: 16px;
                      color: #333;
                      justify-content: space-between;
                      margin-top: -10px;
                    "
                  >
                    <div style="width: 40px; margin-top: 12px">地址</div>
                    <div style="flex: 1; text-align: right">
                      <van-field
                        v-model="msg"
                        placeholder="请输入地址"
                        type="textarea"
                        :row="2"
                        style="
                          line-height: 24px;
                          height: 60px;
                          width: 100%;
                          text-align: right;
                        "
                      />
                      <!-- <input v-model="msg" style="border: none;width: 100%;text-align: right;" type="textarea" placeholder="请输入地址" /> -->
                    </div>
                  </div>
                  <div class="order_btn">
                    <div
                      style="
                        width: 77px;
                        height: 33px;
                        background: #ffa900;
                        border-radius: 5px;
                        opacity: 1;
                        font-size: 16px;
                        font-family: PingFang SC, PingFang SC;
                        color: #ffffff;
                        line-height: 33px;
                        text-align: center;
                      "
                      @click.stop="toOrder(item.id, item, item.flag)"
                    >
                      订餐
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          </div>
        </van-tab>
        <van-tab title="订单">
          <!-- <div class="select">
            <div class="select-item" @click="isShowCanteenListDialog = true">
              <div class="name">{{ currentCanteenName }}</div>
              <img src="../../assets/images/bottomImg.png" alt="" />
            </div>
          </div> -->
          <van-list
            v-if="mealedList && mealedList.length > 0"
            v-model="mealedLoading"
            :finished="mealedFinished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onMealedLoad"
          >
            <div class="reverse">
              <div v-for="(item, index) in mealedList" :key="index">
                <div
                  class="content_detail"
                  style="
                    background: #fff;
                    margin-bottom: 15px;
                    border-radius: 10px;
                  "
                >
                  <div class="detail_top">
                    <div
                      style="
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        height: 50px;
                        align-items: center;
                        background: #ffffff;
                        border-radius: 5px;
                        opacity: 1;
                        font-size: 16px;
                        font-family: PingFang SC, PingFang SC;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          color: #333;
                        "
                      >
                        <img
                          mode="scaleToFill"
                          style="width: 21px; height: 21px; margin-right: 10px"
                          :src="
                            item.mealType === 'breakfast'
                              ? breakfast
                              : item.mealType === 'lunch'
                              ? lunch
                              : item.mealType === 'dinner'
                              ? dinner
                              : ''
                          "
                        />
                        <span style="margin-right: 10px; font-weight: bold">
                          {{ date(item.mealDate) }}
                        </span>
                        <span style="font-weight: bold">
                          ({{ week(item.mealDate) }})
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        color: #333333;
                        font-size: 16px;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ item.canteenName || '食堂' }}
                    </div>
                    <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  height: 50px;
                  align-items: center;
                  width: 50px;
                "
              >
                <span
                  v-if="item.status === 1"
                  style="
                    font-size: 15px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                  "
                >
                  已就餐
                </span>
                <span
                  v-else-if="item.status === 0"
                  style="
                    font-size: 15px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    color: #e64545;
                  "
                >
                  未就餐
                </span>
                <span
                  v-else
                  style="
                    font-size: 15px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    color: #999999;
                  "
                >
                  已取消
                </span>
              </div> -->
                  </div>
                  <div class="detail_content">
                    <div
                      style="
                        height: 44px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 15px;
                        font-size: 16px;
                        color: #333;
                      "
                    >
                      <div>就餐时间</div>
                      <div
                        style="
                          font-family: PingFang SC, PingFang SC;
                          font-weight: 400;
                          color: #666666;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{
                          subTime(item.mealStart) + '~' + subTime(item.mealEnd)
                        }}
                      </div>
                    </div>
                    <div
                      style="
                        height: 44px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 15px;
                        font-size: 16px;
                        color: #333;
                      "
                    >
                      <div>预定时间</div>
                      <div
                        style="
                          font-family: PingFang SC, PingFang SC;
                          font-weight: 400;
                          color: #666666;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ item.createTime }}
                      </div>
                    </div>
                    <div
                      style="
                        height: 44px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 15px;
                        font-size: 16px;
                        color: #333;
                      "
                    >
                      <div>就餐状态</div>
                      <div
                        class="status"
                        :class="
                          item.status === 0
                            ? 'not-meal'
                            : item.status === 1
                            ? 'meal'
                            : 'cancel'
                        "
                      >
                        <!-- 0未就餐 1已就餐 else已取消 -->
                        {{
                          item.status === 0
                            ? '未就餐'
                            : item.status === 1
                            ? '已就餐'
                            : '已取消'
                        }}
                      </div>
                    </div>
                    <div
                      v-for="(citem, cindex) in item.products"
                      :key="cindex"
                      style="
                        height: 44px;
                        display: flex;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 16px;
                        align-items: center;
                      "
                    >
                      <div style="color: #333; font-size: 16px">
                        {{ citem.name }}
                        <span
                          style="
                            border: 1px solid #ccc;
                            padding: 2px 8px;
                            border-radius: 2px;
                            opacity: 1;
                            border: 1px solid #f3ab3c;
                            color: #ffa800;
                            margin-left: 8px;
                            font-size: 14px;
                          "
                        >
                          ￥{{ (citem.price / 100).toFixed(2) }}
                        </span>
                      </div>
                      <div style="color: #666; font-size: 16px">
                        x{{ citem.num }}
                      </div>
                    </div>
                    <div v-if="item.mode === 'take_out_meal'" class="address">
                      <div class="left">地址</div>
                      <div class="right">{{ item.address }}</div>
                    </div>
                    <div
                      style="
                        height: 44px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 15px;
                        font-size: 16px;
                        color: #333;
                      "
                    >
                      <div>合计</div>
                      <div
                        style="
                          font-family: PingFang SC, PingFang SC;
                          font-weight: 400;
                          color: #666666;
                          display: flex;
                          align-items: center;
                        "
                      >
                        ￥{{ (item.amount / 100).toFixed(2) }}
                      </div>
                    </div>
                    <div
                      class="order_Btn"
                      v-if="item.status === 0 && now(item.mealEnd)"
                    >
                      <div class="btn_in">
                        <div
                          v-if="item.status === 0 && now(item.mealEnd)"
                          style="
                            width: 94px;
                            height: 33px;
                            border-radius: 5px;
                            opacity: 1;
                            border: 1px solid #fa5050;
                            color: #fa5050;
                            font-size: 16px;
                            font-family: PingFang SC, PingFang SC;
                            line-height: 33px;
                            text-align: center;
                            cursor: pointer;
                          "
                          @click="handleCancel(item)"
                        >
                          取消订单
                        </div>
                        <div
                          v-if="
                            phone && organizationId
                              ? item.status === 0 && now(item.mealEnd)
                              : item.status === 0 &&
                                now(item.mealEnd) &&
                                item.mode !== 'take_out_meal'
                          "
                          style="
                            width: 94px;
                            height: 33px;
                            background: #fa5050;
                            border-radius: 5px;
                            opacity: 1;
                            color: white;
                            font-size: 16px;
                            font-family: PingFang SC, PingFang SC;
                            color: #ffffff;
                            line-height: 33px;
                            text-align: center;
                            border: 1px solid #fa5050;
                            margin-left: 10px;
                            cursor: pointer;
                          "
                          @click="getQRFn(item.id)"
                        >
                          取餐码
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          <!--          <van-overlay :show="showQR" class-name="show-qs" duration="0" @click="showQR = false">-->
          <!--            <img :src="generateQR" alt="" style="width: 20rem">-->
          <!--          </van-overlay>-->
        </van-tab>
      </van-tabs>
    </div>
    <!-- 食堂列表 -->
    <!-- 消费码 -->
    <van-popup v-model="isPaymentCode" :close-on-click-overlay="false">
      <div class="payment_code">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 16px;
            border-bottom: 1px solid #eaeaea;
          "
        >
          <div style="display: flex; flex-direction: row; align-items: center">
            <img
              style="width: 24px; height: 24px"
              src="../../assets/icon-title-qrcode.png"
              alt
            />
            <div style="color: #333; margin-left: 10px; font-size: 16px">
              取餐码
            </div>
          </div>
          <div style="width: 24px; height: 24px">
            <img
              style="width: 100%; height: 100%"
              @click="isPaymentCode = false"
              src="../../assets/code_close.png"
              alt
            />
          </div>
        </div>
        <div class="code_text">扫描下方二维码取餐</div>
        <div class="code_content">
          <img
            v-if="generateQR"
            style="width: 200px; height: 200px"
            :src="generateQR"
            alt
          />
        </div>
        <div class="first"></div>
        <div class="first2"></div>
        <div class="first3"></div>
        <div class="first4"></div>
      </div>
    </van-popup>
    <van-popup v-model="isShowCanteenListDialog" position="bottom">
      <van-picker
        show-toolbar
        :columns="canteenList"
        @confirm="onConfirmCanteen"
        @cancel="isShowCanteenListDialog = false"
        value-key="name"
      />
    </van-popup>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import moment from 'moment';
import {
  mealCancel,
  mealedAllList,
  mealOrder,
  unMealList,
  getUserSystemSetupAPI,
  deleteUnPaymentOrderAPI,
  getCanteenListAPI,
  getQR
} from '@/api/order';
import QRCode from 'qrcode';
import { Dialog, Toast } from 'vant';
import breakfast from '@/assets/breakfast.png';
import lunch from '@/assets/lunch.png';
import dinner from '@/assets/dinner.png';
export default {
  name: 'Order',
  data() {
    return {
      // 是否显示外送
      isOutsideOrder: false,
      // 是否显示配送地址
      isAddress: false,
      showQR: false,
      generateQR: '',
      userId: '',
      mealedLoading: false,
      mealedFinished: false,
      mealLoading: false,
      mealFinished: false,
      show: false,
      isPaymentCode: false,
      mealPagination: {
        current: 1,
        size: 10,
        total: 0
      },
      breakfast,
      lunch,
      dinner,
      mealedPagination: {
        current: 1,
        size: 10,
        total: 0
      },
      mealShow: true,
      active: 0,
      cancel,
      mealList: [],
      mealedList: [],
      // 是否外送
      isDelivery: false,
      // 地址
      msg: '',
      isShowCanteenListDialog: false,
      currentCanteenName: '全部',
      phone: '',
      canteenList: []
    };
  },
  computed: {
    now() {
      return (time) => {
        const second = moment().format('YYYY-MM-DD HH:mm:ss');
        return moment(second).isBefore(time, 'second');
      };
    },
    date() {
      return (day) => {
        const today = moment().format('YYYY-MM-DD');
        const yesterday = moment(moment().add(1, 'days')).format('YYYY-MM-DD');
        if (day === today) return '今天';
        if (day === yesterday) return '明天';
        return day;
      };
    },
    week() {
      return (time) => {
        const date = moment(time).day();
        const list = {
          1: '星期一',
          2: '星期二',
          3: '星期三',
          4: '星期四',
          5: '星期五',
          6: '星期六',
          0: '星期日'
        };
        return time && list[date];
      };
    },
    section() {
      return (type) => {
        const list = {
          breakfast: '早',
          lunch: '中',
          dinner: '晚'
        };
        return type && list[type];
      };
    },
    totalFee() {
      return (list) => {
        let total = 0;
        list &&
          list.length > 0 &&
          list.map((item) => {
            total += (item.price / 100) * item.num;
            return total;
          });
        return total.toFixed(2);
      };
    },
    subTime() {
      return (time) => {
        return time.substr(-8);
      };
    }
  },
  created() {
    const { userId } = this.$store.getters;
    const { current, size } = this.mealPagination;
    const { active } = this.$route.params ? this.$route.params : '';
    if (active) this.active = active;
    this.userId = userId;
    this.fetch({ userId, current, size });
    this.deleteUnPaymentOrderFn(userId);
    this.getUserSystemSetupFn();
  },
  methods: {
    async getQRFn(id) {
      const { code, data, message } = await getQR(id);
      if (code === 0) {
        this.generateQR = await QRCode.toDataURL(data);
        this.isPaymentCode = true;
      } else {
        Toast.fail({
          message,
          duration: 1000
        });
      }
    },
    async onConfirmCanteen(item) {
      const { userId } = this.$store.getters;
      const { current, size } = this.mealPagination;
      this.isShowCanteenListDialog = false;
      if (this.mode === 0) {
        this.mealList = [];

        await this.fetch({
          userId,
          current,
          size,
          canteenId: item.id
        });
      } else {
        this.mealedList = [];
        await this.getMealedList({ userId, current, size, canteenId: item.id });
      }
      this.currentCanteenName = item.name;
    },
    // 获取食堂列表
    async getCanteenList() {
      try {
        this.show = true;
        const res = await getCanteenListAPI({
          current: 1,
          size: 100,
          total: 0,
          organizationId: this.user.organizationId
        });
        this.canteenList = res.data.records;
        this.canteenList.unshift({ name: '全部', id: '' });
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
      }
    },
    // 获取取餐码
    async openQR(id) {
      // console.log(id, 123123);
      this.$router.push({
        name: 'MealCode',
        params: { id }
      });
    },
    // 取消订单
    handleCancel(item) {
      const { userId, fetch, getMealedList } = this;
      Dialog.confirm({
        title: '提示',
        message: '确定取消订单吗'
      })
        .then(async () => {
          this.mealPagination = this.mealedPagination = {
            current: 1,
            size: 10,
            total: 0
          };
          const { current, size } = this.mealPagination;
          const res = await mealCancel(item.id);
          const { code, message } = res;
          if (code === 0) {
            Toast.success('取消成功');
            this.mealList = this.mealedList = [];
            fetch({ userId, current, size });
            getMealedList({ userId, current, size });
            this.active = 1;
          } else {
            console.log(message);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onMealLoad() {
      const { userId } = this;
      const { current, size, total } = this.mealPagination;
      if (current * size >= total) {
        this.mealFinished = true;
      } else {
        this.fetch({ userId, current: current + 1, size });
      }
    },
    onMealedLoad() {
      const { userId } = this;
      const { current, size, total } = this.mealedPagination;
      if (current * size >= total) {
        this.mealedFinished = true;
      } else {
        // console.log(212);
        this.getMealedList({ userId, current: current + 1, size });
      }
    },
    async fetch(params) {
      const { mealList } = this;
      mealList.length === 0 && (this.show = true);
      const res = await unMealList(params);
      const { code, data, message } = res;
      // console.log(data);
      if (code === 0) {
        this.mealList = [...mealList, ...data.records];
        this.mealPagination = {
          current: data.current,
          size: data.size,
          total: data.total
        };
        this.mealList.forEach((item) => {
          this.$set(item, 'flag', false);
        });
        // console.log(this.mealList);

        setTimeout(() => {
          this.mealLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    async getMealedList(params) {
      const { mealedList } = this;
      mealedList.length === 0 && (this.show = true);
      const res = await mealedAllList(params);
      const { code, data, message } = res;
      if (code === 0) {
        this.mealedList = [...mealedList, ...data.records];
        this.mealedPagination = {
          current: data.current,
          size: data.size,
          total: data.total
        };
        setTimeout(() => {
          this.mealedLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
      // console.log(this.mealedList, 1111);
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    toOrder(id, item, flag) {
      this.$store.dispatch('user/getInfo').then(() => {
        const { userId, fetch, getMealedList } = this;
        const { openOrder } = this.$store.getters;
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        const before = moment(item.endTime).isBefore(now, 'second');
        if (before) {
          Toast('已过订餐时间，订餐失败！');
          return;
        }
        if (flag && this.msg.trim() === '') {
          Dialog.confirm({
            title: '提示',
            message: '请输入地址'
          });
          return;
        }
        const products = item.products || [];
        if (openOrder === 0 && products.length === 0) {
          this.mealPagination = this.mealedPagination = {
            current: 1,
            size: 10,
            total: 0
          };
          const { current, size } = this.mealPagination;
          Dialog.confirm({
            title: '提示',
            message: '确定预定此餐吗'
          })
            .then(async () => {
              const originalCost = 0;
              const amount = 0;
              const reduceCost = 0;
              const order = {
                mealId: item.id,
                originalCost,
                amount,
                reduceCost,
                products: [],
                canteenId: item.canteenId
              };
              const res = await mealOrder(userId, { order });
              const { code, message } = res;
              if (code === 0) {
                Toast.success('预定成功');
                this.mealList = this.mealedList = [];
                fetch({ userId, current, size });
                getMealedList({ userId, current, size });
                this.active = 1;
              } else {
                console.log(message);
              }
            })
            .catch(() => {
              // on cancel
            });
        } else {
          console.log(item, '食堂');

          this.$router.push({
            name: 'OrderDetail',
            params: { id, msg: this.msg, flag, canteenId: item.canteenId }
          });
        }
      });
    },
    clickFn(index) {
      this.mealList[index].flag = !this.mealList[index].flag;
      // console.log(this.mealList[index].flag);
    },
    async getUserSystemSetupFn() {
      const organizationId = JSON.parse(
        sessionStorage.getItem('personInfo')
      ).organizationId;
      const res = await getUserSystemSetupAPI(organizationId);
      if (res.code === 0 && res.data !== null) {
        if (JSON.parse(JSON.stringify(res.data.setting)) !== '{}') {
          const data = JSON.parse(res.data.setting).dev_set;
          data.forEach((item) => {
            if (item.title === '消费配置') {
              item.switchList.forEach((citem) => {
                if (citem.title === '订餐外送') {
                  this.isOutsideOrder = citem.switchFlag;
                }
              });
            }
          });
        }
      }
    },
    deleteUnPaymentOrderFn(userId) {
      deleteUnPaymentOrderAPI(userId)
        .then((res) => {
          if (res.code === 0) {
            const { userId } = this.$store.getters;
            const { current, size } = this.mealPagination;
            this.getMealedList({ userId, current, size });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    //position: relative;
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  & > .content {
    padding: 48px 0 0;
    background-color: #f7f7f7;
    .reverse {
      //margin-top: 1rem;
      //padding: 0 1rem 7rem;
      //position: fixed;
      //width: 100%;
      //box-sizing: border-box;
      //height: 100%;
      //overflow: scroll;
      .dish-card {
        //margin-bottom: 1.25rem;
        //border-bottom: 2px dashed #ccc;
        padding-bottom: 1rem;
        .title {
          color: black;
          display: flex;
          justify-content: space-between;
          padding: 0.5rem;
          .day {
            width: 50%;
          }
        }
      }
      .content_detail {
        background: #fff;
        margin-bottom: 10px;

        .detail_top {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 50px;
          align-items: center;
          background: #ffffff;
          border-radius: 5px 5px 0px 0px;
          opacity: 1;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          box-sizing: border-box;
          padding-right: 16px;
          margin-left: 16px;
          .right {
            margin-right: 15px;
          }
          .left {
            display: flex;
            align-items: center;
            font-weight: 900;
          }
        }
        .detail_top::after {
          content: '';
          width: 100%;
          height: 1px;
          transform: scaleY(0.5);
          background-color: #e5e5e5;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .detail_content {
          .list {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              margin-left: 15px;
            }
            .right {
              margin-right: 15px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #666666;
              display: flex;
              align-items: center;
              .right_tabLeft,
              .right_tabRight {
                width: 45px;
                height: 33px;
                border-radius: 2px;
                opacity: 1;
                border: 1px solid #707070;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 33px;
                text-align: center;
                margin-left: 5px;
              }
              .active {
                color: #00b893;
                border: 1px solid #00b893;
              }
            }
            .active {
              color: #00b893;
              border: 1px solid #00b893;
            }
          }
          .address {
            margin-left: 15px;
            height: 60px;
            display: flex;
            align-items: center;

            .left {
              margin-right: 10px;
              img {
                width: 15px;
                height: 20px;
              }
            }
            .right {
              margin-right: 15px;
              input {
                border: none;
              }
            }
          }
          .meal_list {
            height: 44px;
            display: flex;
            justify-content: space-between;
            margin-left: 15px;

            align-items: center;
            .left {
              font-weight: 900;
              .left_price {
                border: 1px solid #ccc;
                padding: 3px 10px;
                border-radius: 2px;
                opacity: 1;
                border: 1px solid #f3ab3c;
                color: #f3ab3c;
                margin-left: 10px;
              }
            }
            .right {
              margin-right: 15px;
            }
          }
          .order_btn {
            height: 55px;
            display: flex;
            flex-direction: row;
            justify-content: end;
            box-sizing: border-box;
            padding: 0 16px;
            .btn {
              width: 77px;
              height: 33px;
              background: #ffa900;
              border-radius: 5px;
              opacity: 1;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC;
              color: #ffffff;
              line-height: 33px;
              text-align: center;
            }
          }
          .order_Btn {
            height: 60px;
            position: relative;
            .btn_in {
              position: absolute;
              height: 60px;
              display: flex;
              align-items: center;
              right: 15px;
              .btn2 {
                width: 94px;
                height: 33px;
                background: #00b893;
                border-radius: 5px;
                opacity: 1;
                color: white;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 900;
                color: #ffffff;
                line-height: 33px;
                text-align: center;
                border: 1px solid #52b595;
                margin-left: 5px;
              }
              .btn1 {
                width: 94px;
                height: 33px;
                border-radius: 5px;
                opacity: 1;
                border: 1px solid #52b595;
                color: #52b595;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 900;
                line-height: 33px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .active {
          background: skyblue;
          color: white;
        }
      }

      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.status {
  width: 55px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
}

.not-meal {
  background-color: #ffa900;
}
.meal {
  background-color: #00b893;
}
.cancel {
  background-color: #ed3944;
}
.select {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 15.5px 16px;
  .select-item {
    display: flex;
    align-items: center;

    img {
      width: 19px;
      height: 19px;
    }
    .name {
      color: #666666;
      font-size: 18px;
      font-weight: 400;
      margin-right: 4px;
    }
  }
}

.select::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
  background-color: #e5e5e5;
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-tabs__nav--card {
  border: 1px solid #607483;
  height: 2.5rem;
  border-radius: 1.25rem;
  overflow: hidden;
}
::v-deep .van-tab.van-tab--active {
  // background-color: #607483 !important;
  color: #f56c6c;
}
::v-deep .van-tab {
  // border-right: 1px solid #607483;
  height: 46px;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}
::v-deep .dialog-footer {
  display: flex;
  justify-content: space-around;
  padding: 1rem 1.875rem;
}
::v-deep .van-popup--center {
  border-radius: 1rem;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.7); */
}
::v-deep .van-stepper__input {
  font-size: 1rem;
}
::v-deep .van-list {
  margin-top: 1rem;
  padding: 0 1rem 7rem;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;
  background-color: #f7f7f7;
}
.payment_code {
  width: 316px;
  height: 410px;
  position: relative;
  .top {
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    position: relative;
    .left,
    .right {
      height: 30px;
      width: 30px;
      margin: 0 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 5px;
    }
    .center {
      font-size: 20px;
      font-weight: 900;
    }
  }
  .code_text {
    text-align: center;
    color: #666666;
    font-size: 14px;
    margin-top: 32px;
  }
  .code_content {
    width: 166px;
    height: 166px;
    background: pink;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom_text {
    text-align: center;
    color: #00b893;
    font-size: 14px;
  }
  .first {
    height: 24px;
    width: 24px;
    border-left: 1px solid #c7000b;
    border-top: 1px solid #c7000b;
    position: absolute;
    left: 50px;
    top: 120px;
  }
  .first2 {
    height: 24px;
    width: 24px;
    border-left: 1px solid #c7000b;
    border-bottom: 1px solid #c7000b;
    position: absolute;
    left: 50px;
    bottom: 75px;
  }
  .first3 {
    height: 24px;
    width: 24px;
    border-right: 1px solid #c7000b;
    border-top: 1px solid #c7000b;
    position: absolute;
    right: 50px;
    top: 120px;
  }
  .first4 {
    height: 24px;
    width: 24px;
    border-right: 1px solid #c7000b;
    border-bottom: 1px solid #c7000b;
    position: absolute;
    right: 50px;
    bottom: 75px;
  }
}
</style>
